import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");
  require("./favorite-list");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  $(".single-apartment__slider").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  $('.single-apartment__slider').slick({
    infinite: false,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button class="slide-arrow slide-arrow-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="slide-arrow slide-arrow-next"><i class="fas fa-chevron-right"></i></button>'
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  if ($(document).width() < 1200 && $('.single-apartment__search')) {
      $('.desk').appendTo('.mob');
  }

});
