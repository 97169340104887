import {
    getCookie,
    setCookie
  } from "./cookie-bar";

let favListArray = []
let favListString = getCookie('favorite');

if (favListString) {
    favListArray = JSON.parse(favListString);
}

if (favListArray.length) {

    setTimeout(() => {

        favListArray = JSON.parse(favListString);

            $('.bookmark').addClass('open');
            $('.favorite span').text(favListArray.length);

            document.querySelectorAll('.add-fav').forEach((item, i)=>{

                favListArray.forEach((item2, j)=>{
                    
                    if (item.dataset.id == item2) {
                        item.classList.add('active');
                        $('.favorite span').text(favListArray.length);
                    }
        
                })
        
            });
        
    }, 500);

}

$('.add-fav').on('click', function(){

    if ($(this).hasClass('active')) {
        remFromFavorite($(this));
    } else {
        addToFavorite($(this));
    }
});

$('.rem-fav').on('click', function() {
    remFromFavorite($(this));
});

function remFromFavorite(element) {

    let remItem = element.data('id');

    if (window.location.pathname == '/polubione') {
        element.parent().remove();
    }

    favListArray = favListArray.filter(item => item !== remItem);

    $('.favorite span').text(favListArray.length);

    setCookie('favorite', JSON.stringify(favListArray), 30);

    element.removeClass('active');

}

function addToFavorite(element) {

    favListArray.push(element.data('id'));

    favListArray = [...new Set(favListArray)];

    $('.favorite span').text(favListArray.length);

    $('.bookmark').addClass('open');

    setCookie('favorite', JSON.stringify(favListArray), 30);

    element.addClass('active');

}
